/* 

====== Fade effect ======

*/
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}


/* 

====== Zoom effect ======

*/
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-zoom-in.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-wrap.mfp-removing .mfp-content {
  transform: scale(0.8);
  opacity: 0;
}


/* 

====== Move-horizontal effect ======

*/
.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  transition: all 0.3s;
}

.mfp-move-horizontal.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-move-horizontal.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-move-horizontal.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50px);
}

.mfp-move-horizontal.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: translateX(0);
}

.mfp-move-horizontal.mfp-wrap.mfp-removing .mfp-content {
  transform: translateX(50px);
  opacity: 0;
}


/* 

====== Move-from-top effect ======

*/
.mfp-move-from-top.mfp-bg {
  opacity: 0;
  transition: all 0.2s;
}

.mfp-move-from-top.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-move-from-top.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-move-from-top.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.2s;
  transform: translateY(-100px);
  vertical-align: top;
}

.mfp-move-from-top.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: translateY(0);
}

.mfp-move-from-top.mfp-wrap.mfp-removing .mfp-content {
  transform: translateY(-50px);
  opacity: 0;
}


/* 

====== 3d unfold ======

*/
.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}

.mfp-3d-unfold.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-3d-unfold.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-3d-unfold.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(-60deg);
  perspective: 2000px;
}

.mfp-3d-unfold.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: rotateY(0deg);
}

.mfp-3d-unfold.mfp-wrap.mfp-removing .mfp-content {
  transform: rotateY(60deg);
  opacity: 0;
}

/* 

====== Zoom-out effect ======

*/
.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-out.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-zoom-out.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-zoom-out.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}

.mfp-zoom-out.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-out.mfp-wrap.mfp-removing .mfp-content {
  transform: scale(1.3);
  opacity: 0;
}


/* 

====== Newspaper effect ======

*/
.mfp-newspaper.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}

.mfp-newspaper.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-newspaper.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-newspaper.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transition: all 0.5s;
  transform: scale(0) rotate(500deg);
}

.mfp-newspaper.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

.mfp-newspaper.mfp-wrap.mfp-removing .mfp-content {
  transform: scale(0) rotate(500deg);
  opacity: 0;
}
