@import "normalize.css";
@import "purecss/build/pure-nr";
@import "purecss/build/grids-responsive";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "https://fonts.googleapis.com/css?family=Work+Sans:200,400,700,600|Source+Sans+Pro:200,400,700,600";
@import "modules/css-conf";
@import "modules/populities";
@import "modules/mixins";
@import "modules/comp-vue-transitions";
@import "modules/comp-vue-text-editable";
@import "modules/comp-vue-autocomplete";
@import "magnific-popup/dist/magnific-popup.css";
@import "modules/comp-magnific-popup-animations";

:root {
  --bg-dark-grey: #616161;
  --bg-grey: #ddd;
  --bg-soft-grey: #f2f2f2;
  --bg-yellow: #fef7e6;
  --bg-dark-yellow: #f1e4c5;
  --space: #{$f6};
  --color-grey: #828282;
  --color-dark-grey: #{$color_headers};
  --font-family: #{$font_text};

  // --font-family-alt has to be like this because admin overwrites the font_text SASS var
  --font-family-alt: "Source Sans Pro", "Helvetica Neue", "Arial", "sans-serif";
}

.dashboards-maker {
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  .layout-header {
    @include flex-align(space-between, stretch);

    &-editable {
      padding: var(--space);
      background-color: var(--bg-grey);
      box-sizing: border-box;
      flex: 1;

      &.is-highlight {
        background-color: var(--bg-dark-yellow);
      }
    }

    &-shadow {
      background-color: var(--bg-dark-grey);
      flex: 0 0 42px;
    }
  }

  .layout-main {
    @include flex-align(space-between, stretch);

    flex: 1;
    flex-direction: column;

    @include min-screen(768) {
      flex-direction: row;
    }

    aside {
      flex: 0 0 20%;
      background-color: var(--bg-soft-grey);
      padding: 12px;
    }

    article {
      flex: 1;
      padding: 32px;
    }
  }

  &--header {
    @include flex-align(space-between);

    &__buttons {
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
    }
  }

  &--button {
    @include flex-align(space-between, baseline);
    @include border-radius(4px);

    font-size: $f8;
    font-family: var(--font-family);
    line-height: 1.25;
    padding: 0.45em 1em 0.3em;

    &:not(:first-child) {
      margin-left: calc(2 * var(--space));
    }

    &.is-shaking {
      animation: shake 250ms infinite;
    }

    @keyframes shake {
      2% {
        transform: translate(1.5px, 1.5px) rotate(1.5deg);
      }

      4% {
        transform: translate(0.5px, -0.5px) rotate(0.5deg);
      }

      6% {
        transform: translate(1.5px, 2.5px) rotate(1.5deg);
      }

      8% {
        transform: translate(0.5px, 2.5px) rotate(0.5deg);
      }

      10% {
        transform: translate(2.5px, 1.5px) rotate(0.5deg);
      }

      12% {
        transform: translate(-0.5px, -1.5px) rotate(-0.5deg);
      }

      14% {
        transform: translate(1.5px, 2.5px) rotate(-0.5deg);
      }

      16% {
        transform: translate(2.5px, -1.5px) rotate(1.5deg);
      }

      18% {
        transform: translate(2.5px, 2.5px) rotate(-0.5deg);
      }

      20% {
        transform: translate(-0.5px, -1.5px) rotate(1.5deg);
      }

      22% {
        transform: translate(-1.5px, -0.5px) rotate(0.5deg);
      }

      24% {
        transform: translate(2.5px, -1.5px) rotate(1.5deg);
      }

      26% {
        transform: translate(0.5px, 2.5px) rotate(1.5deg);
      }

      28% {
        transform: translate(-0.5px, 2.5px) rotate(-0.5deg);
      }

      30% {
        transform: translate(0.5px, 2.5px) rotate(1.5deg);
      }

      32% {
        transform: translate(-1.5px, -0.5px) rotate(-0.5deg);
      }

      34% {
        transform: translate(0.5px, -0.5px) rotate(0.5deg);
      }

      36% {
        transform: translate(-1.5px, 0.5px) rotate(1.5deg);
      }

      38% {
        transform: translate(-1.5px, 1.5px) rotate(1.5deg);
      }

      40% {
        transform: translate(2.5px, 0.5px) rotate(0.5deg);
      }

      42% {
        transform: translate(0.5px, 0.5px) rotate(1.5deg);
      }

      44% {
        transform: translate(-0.5px, 0.5px) rotate(0.5deg);
      }

      46% {
        transform: translate(2.5px, 1.5px) rotate(1.5deg);
      }

      48% {
        transform: translate(2.5px, 1.5px) rotate(-0.5deg);
      }

      50% {
        transform: translate(-1.5px, 2.5px) rotate(-0.5deg);
      }

      52% {
        transform: translate(0.5px, -1.5px) rotate(1.5deg);
      }

      54% {
        transform: translate(0.5px, -1.5px) rotate(0.5deg);
      }

      56% {
        transform: translate(1.5px, -1.5px) rotate(-0.5deg);
      }

      58% {
        transform: translate(1.5px, 0.5px) rotate(0.5deg);
      }

      60% {
        transform: translate(-0.5px, 0.5px) rotate(0.5deg);
      }

      62% {
        transform: translate(0.5px, 0.5px) rotate(-0.5deg);
      }

      64% {
        transform: translate(-1.5px, -0.5px) rotate(1.5deg);
      }

      66% {
        transform: translate(-1.5px, 2.5px) rotate(-0.5deg);
      }

      68% {
        transform: translate(-1.5px, 1.5px) rotate(0.5deg);
      }

      70% {
        transform: translate(0.5px, 1.5px) rotate(0.5deg);
      }

      72% {
        transform: translate(-1.5px, 2.5px) rotate(0.5deg);
      }

      74% {
        transform: translate(2.5px, 0.5px) rotate(-0.5deg);
      }

      76% {
        transform: translate(0.5px, -1.5px) rotate(1.5deg);
      }

      78% {
        transform: translate(0.5px, 1.5px) rotate(0.5deg);
      }

      80% {
        transform: translate(-0.5px, -0.5px) rotate(0.5deg);
      }

      82% {
        transform: translate(1.5px, 1.5px) rotate(1.5deg);
      }

      84% {
        transform: translate(0.5px, 0.5px) rotate(0.5deg);
      }

      86% {
        transform: translate(-0.5px, 0.5px) rotate(1.5deg);
      }

      88% {
        transform: translate(1.5px, 0.5px) rotate(1.5deg);
      }

      90% {
        transform: translate(-0.5px, 2.5px) rotate(-0.5deg);
      }

      92% {
        transform: translate(-1.5px, -1.5px) rotate(1.5deg);
      }

      94% {
        transform: translate(-1.5px, 2.5px) rotate(1.5deg);
      }

      96% {
        transform: translate(-1.5px, -1.5px) rotate(1.5deg);
      }

      98% {
        transform: translate(1.5px, -1.5px) rotate(1.5deg);
      }

      0%,
      100% {
        transform: translate(0, 0) rotate(0);
      }
    }

    &__link {
      padding: 0;
      text-transform: none;
      font-weight: normal;
      text-align: initial;
      color: inherit;
      background: none;
      text-decoration: underline;

      > :first-child {
        margin-right: calc(0.5 * var(--space));
      }

      > :not(:first-child) {
        display: none;

        @include min-screen(768) {
          display: block;
        }
      }

      &:hover,
      &:focus {
        background: none;
        box-shadow: none;
        border: 0;
      }
    }

    &__square {
      padding: 10px;

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }

  &--checkbox {
    @include flex-align(space-between);

    cursor: pointer;
    font-family: var(--font-family);

    input[type="checkbox"] {
      display: block;
      margin-right: calc(0.5 * var(--space));
    }
  }

  &--aside {
    &__title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: $f8;
      color: var(--color-dark-grey);
      font-family: var(--font-family);
    }

    &__footer {
      white-space: pre-line;
      font-size: $f7;
      font-weight: 400;
      color: var(--color-grey);
      font-family: var(--font-family-alt);
    }
  }

  &--small-card {
    background-color: $color_main_negative;
    border: 2px dashed #bdbdbd;
    color: var(--color-dark-grey);
    font-family: var(--font-family);
    font-size: $f7;
    font-weight: 700;
    padding: var(--space);
    box-sizing: border-box;
    min-height: 60px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &--widget {
    --border-width: 4px;

    height: 100%;
    box-sizing: border-box;
    position: relative;
    border: var(--border-width) solid transparent;
    transition: all 250ms;

    &__editing {
      border: var(--border-width) solid var(--color-base);

      @include border-radius(4px);
    }

    &__toolbox {
      @include flex-align(space-between);
      @include border-radius($top: 4px);

      position: absolute;
      cursor: pointer;
      top: 0;
      right: calc(-1 * var(--border-width));
      transform: translateY(-100%);
      background-color: var(--color-base);
      color: $color_main_negative;
    }

    &__form {
      background-color: var(--bg-yellow);
      margin: calc(-1 * var(--space));
      padding: var(--space);
      height: 100%;

      @include flexbox(calc(3 * var(--space)), column);

      &-title {
        font-weight: bold;
        display: block;
      }

      &-text[type="text"] {
        display: block;
        height: 30px;
        width: 100%;

        @include border-radius(3px);
      }

      &-label {
        @include flex-align(flex-start, baseline);
        @include flexbox(calc(0.5 * var(--space)));

        cursor: pointer;
      }

      &-result {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
      }

      &-radio[type="radio"] {
        display: inline-block;
        cursor: auto;
      }

      &-submit {
        margin-top: auto;
        align-self: flex-end;
      }
    }
  }
}
