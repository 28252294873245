/*
 * Breadcrumbs
 *
 */
.breadcrumb {
  margin: 0 0 2em;
  background: #fff;

  // border-bottom: 1px solid #DFDFDF;
  padding: 1em 0;

  a,
  span {
    margin: 0 0.5em;
    font-size: 1.15em;
  }

  a:first-child {
    margin-left: 0;
  }

  @include screen(740) {
    padding: 0.75em 0;
    margin: 0 0 0.5em;

    .bread_links {
      line-height: 1.3em;
      font-size: 0.75em;
    }
  }
}

.breadcrumb.is_stuck {
  z-index: 10000;

  @include box-shadow(0, 3px, 6px, rgba(0, 0, 0, 0.2));
}

.breadcrumb.year_only {
  margin-bottom: 0;

  .bread_hover {
    max-width: 100px;
    display: inline-block;
  }

  .bread_links {
    background: rgba(var(--color-base-string), 0.1);
    padding: 0.3em 0.75em;

    @include border-radius(4px);

    font-weight: 200;

    a {
      text-decoration: none;
      color: $color_text;
    }

    .fas {
      vertical-align: middle;
      margin-top: -10px;
      color: var(--color-base);
    }
  }

  .line_browser {
    padding: calc(1.5em - 1px) 2em 2em;

    .col {
      padding: 0;
      width: 100%;
      font-size: 1.2em;
      font-weight: 200;

      a {
        display: block;
      }
    }
  }
}

.header_block_inline {
  h2 {
    margin: 0.8em 0 2em;
  }
}

.line_browser {
  z-index: 100;
  overflow: hidden;
  white-space: nowrap;
  display: none;
  position: absolute;
  background: #fff;
  padding: 2em 1em 1em 2em;
  margin-left: calc(-2em - 1px);
  margin-top: calc(-3.5em - 1px);
  border: 1px solid #dfdfdf;

  @include border-radius(4px);

  box-sizing: border-box;

  @include box-shadow(0, 3px, 6px, rgba(0, 0, 0, 0.2));

  .col {
    display: inline-block;
    display: none;
    float: left;
    padding: 0 1em 0 0;
  }

  .col[data-level='0'] {
    display: block;
  }

  .bread_links {
    margin: 0 0 1em;
  }

  table {
    font-size: 0.85em;
  }

  td.selected,
  td.selected_no_children {
    border-left: 2px solid rgba(var(--color-base-string), 0.5);
    position: relative;
  }

  td.selected::after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border-style: solid;
    border-width: 6px 0 6px 11.3px;
    border-color: transparent transparent transparent rgba(var(--color-base-string), 0.5);
    top: 38%;
    left: 100%;
  }
}

.bread_links {
  display: inline-block;
}

.open_line_browser,
.close_line_browser {
  opacity: 0.5;
  color: var(--color-base);
  cursor: pointer;
  margin: 0 1em 0 0;
}

/*
 * Admin Breadcrumb
 *
 */
.admin_breadcrumb {
  font-size: 0.75em;
  text-transform: uppercase;
  margin: 0 0 1rem;
}

/**
 * Pagination
 */

.pagination {
  display: flex;
  padding-left: 0;

  @include reset_ul_li;

  li {
    &:first-child {
      display: none;

      @include min-screen(740) {
        display: inline-block;
      }
    }

    &:first-child a {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child a {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  a {
    text-decoration: none;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--color-base);
    background-color: #fff;
    border: 1px solid #ddd;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      background-color: #eceeef;
      border-color: #ddd;
    }
  }

  .disabled {
    color: rgba(var(--color-base-string), 0.5);
    pointer-events: none;
  }

  .active a {
    border-color: var(--color-base);
    background: var(--color-base);
    color: white;
    font-weight: 700;
  }
}
