/*

TEMPLATE STYLES

Hint:
In order to generate more custom categories, refer to https://gobierto.readme.io/docs/planificiacion#section-backoffice

*/

/* COLOR MANAGER */
:root {
  // colors must be an RGB string
  --category-1: 219,82,75;  // #db524b;
  --category-2: 0,144,158; // #00909e;
  --category-3: 241,173,68; // #f1ad44;
  --category-4: 133,168,28; // #85a81c;
  --category-5: 0,82,158; // #00529e;
  --category-6: 41,0,158; // #29009E;
  --category-7: 120,35,128; // #782380;
  --category-8: 174,100,78; // #AE644E;
  --category-9: 195,172,82; // #C3AC52;
  --category-10: 122,140,58; // #7A8C3A;
  --category-11: 58,122,133; // #3A7A85;
  --category: var(--category-1);
}

.gobierto_planification {
  .level_0 .category {
    background-color: rgba(var(--category), 0.9);

    .progress {
      background-color: rgba(var(--category), 1);
    }
  }

  .category {
    background: rgba(var(--category), 0.1);
    color: rgba(var(--category), 1);

    .node-list .node-title h3,
    .node-list .node-title h3 a {
      color: rgba(var(--category), 1);
    }

    .node-list .node-title .fas {
      background: rgba(var(--category), 0.1);
    }

    .node-list.cat--negative .node-title h3,
    .node-list.cat--negative .node-title h3 a {
      color: $color_main_negative;
    }

    .node-list.cat--negative .node-title .fas {
      background: $color_main_negative;
      color: rgba(var(--category), 1);
    }

    .node-breadcrumb {
      border: 1px solid rgba(var(--category), 0.75);
      color: rgba(var(--category), 1);
    }

    .node-breadcrumb a {
      color: rgba(var(--category), 1);
    }

    .node-action-line table tbody tr:hover td {
      background-color: rgba(var(--category), 0.2);
    }

    .node-project-detail .project-mandatory .mandatory-progress > div {
      background-color: rgba(var(--category), 1);
    }

    .node-project-detail .project-description .project-description__more {
      border-color: rgba(var(--category), 1);
      color: rgba(var(--category), 1);
    }

    .cat--negative {
      background: rgba(var(--category), 1);
      color: $color_main_negative;
    }

    .cat--negative h3,
    .cat--negative h3 a {
      color: $color_main_negative;
    }
  }
}
