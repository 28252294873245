.gobierto-text-editable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  max-width: 75vw;

  > :first-child {
    flex: 1;
    margin: 0;
    padding: 0;
    min-width: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-family);

    &:focus {
      border-radius: 4px;
      margin: -5px;
      padding: 5px;
      background-color: white;
      outline: none;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    }
  }

  > :last-child:not(:only-child) {
    margin-left: 1em;
  }
}