/*
 * SDG
 *
 */
.sdg_list_grid {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  grid-gap: 3vw;
  justify-items: stretch;

  img {
    width: 100%;
  }
}

.sdg_list_header {
  .sdg_icons {
    display: flex;
    flex-wrap: wrap;

    .sdg_icon {
      max-width: calc(100% / 17);
      height: auto;

      @include screen(480, 920) {
        max-width: calc(100% / 9);
      }

      @include screen(480) {
        max-width: calc(100% / 5);
      }

      img {
        width: 100%;
        height: auto;
        display: inherit;
      }
    }
  }
}

.sdg_icon_inactive {
  opacity: .25;
  filter: grayscale(100%);
}

.sdg_label {
  padding: 1em;
  color: #fff;

  p {
    margin: 0;
  }

  .sdg_title {
    text-transform: uppercase;
    font-weight: 700;
  }

  .sdg_description {
    opacity: .65;
  }
}

/* Official colors */
.sdg-1 {
  background: #e5243b;
}

.sdg-2 {
  background: #dda63a;
}

.sdg-3 {
  background: #4c9f38;
}

.sdg-4 {
  background: #c5192d;
}

.sdg-5 {
  background: #ff3a21;
}

.sdg-6 {
  background: #26bde2;
}

.sdg-7 {
  background: #fcc30b;
}

.sdg-8 {
  background: #a21942;
}

.sdg-9 {
  background: #fd6925;
}

.sdg-10 {
  background: #dd1367;
}

.sdg-11 {
  background: #fd9d24;
}

.sdg-12 {
  background: #bf8b2e;
}

.sdg-13 {
  background: #3f7e44;
}

.sdg-14 {
  background: #0a97d9;
}

.sdg-15 {
  background: #56c02b;
}

.sdg-16 {
  background: #00689d;
}

.sdg-17 {
  background: #19486a;
}
